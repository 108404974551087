import { motion } from 'framer-motion';
import { useContext } from 'react';
import { PageContext } from '../contexts';
import { useInViewScroll, useSanitizedTranslation } from '../hooks';
import { CTA, IImage } from '../types';
import { isEnterprise } from '../utils';
import { IFormField, XumoForm } from '.';
import { useScrolledTheme } from 'ui';

export interface LeadGenFormProps {
  key?: string;
  heading: string;
  body: string;
  formFields: IFormField[];
  CTA: CTA;
  backgroundColor?: string;
  bottomBackgroundColor?: string;
  backgroundImage?: IImage;
  headingOne?: boolean;
}

const squiggleVariant = {
  hidden: {
    opacity: 0,
    scale: 1.05,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
      restDelta: 0.001,
    },
  },
};

export function LeadGenForm({ CTA, heading, body, formFields, backgroundColor, bottomBackgroundColor, backgroundImage, headingOne }: LeadGenFormProps) {
  const { ref, progress: y } = useInViewScroll([200, 0], ['start 100vh', 'end 0vh']);
  const { pageCtx } = useContext(PageContext);
  const { t } = useSanitizedTranslation();

  const { host } = pageCtx;

  const HeadingComponent = headingOne ? motion.h1 : motion.h2;

  const { componentInView } = useScrolledTheme({
    ref,
    background: backgroundColor,
    // TODO: add accent to directus
    accent: '#000000',
    text: '#000000',
  });

  return (
    <>
      <div ref={ref} className="relative z-40 py-16 w-full">
        <section id="contact" className="relative w-full wrapper" data-testid={'lead-form-section'}>
          <div className="relative flex w-full flex-col items-center">
            <div
              className="form.modal content-wrapper w-full grow-1 z-20 flex flex-col items-center
        justify-center rounded-md bg-[#f5f7f8]"
            >
              <HeadingComponent
                className="type-heading mb-8 px-12 text-center md:mb-0 md:px-0"
                data-i18n={heading}
                dangerouslySetInnerHTML={{ __html: t(heading) }}
              />
              <div className="type-body p-6 text-center lg:w-1/2 allow-html" data-i18n dangerouslySetInnerHTML={{ __html: body }} />
              <div className="w-full lg:w-2/3">
                <XumoForm
                  formFields={formFields}
                  CTA={CTA}
                  formAction={{ submissionEndpoint: '/api/contact', successMessage: '' }}
                  styleOverrides={{
                    CTA: {
                      general: `text-white border-transparent py-2 px-12`,
                      disabledColor: 'bg-xumoSmoke',
                      enabledColor: 'bg-xumoTeal',
                    },
                    formField: ``,
                    form: 'w-full justify-center items-center px-8 sm:px-16 sm:pt-8 gap-4',
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
