import { Field } from 'formik';
import { useContext } from 'react';
import { PageContext, useSanitizedTranslation } from '..';
import { InputType } from '../types';
import { isEnterpriseOrShopper } from '../utils';
import { StringSchema } from 'yup';
export interface IFormField {
  type: InputType;
  name: string;
  placeholder: string;
  width?: 'full' | 'half';
  customValidation?: StringSchema;
}

export type FormFieldProps = IFormField & {
  handleChange: (e: any) => void;
  value: string | string[];
  focusedElementId: string | undefined;
  styleOverride?: string;
  errorId?: string;
};

export const FormField = ({ name, placeholder, type, handleChange, value, errorId, width = 'half', styleOverride = '' }: FormFieldProps) => {
  const { t } = useSanitizedTranslation();
  const { pageCtx } = useContext(PageContext);
  const { host } = pageCtx;

  const focusBorder = isEnterpriseOrShopper(host) ? 'focus:border-xumoAegean' : 'focus:border-xumoBerry';
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (type) {
    case InputType.Textarea:
      return (
        <div className="relative w-full">
          <label htmlFor={name}>{placeholder}</label>
          <textarea
            className={`
              mt-2 rounded-md outline outline-2 outline-[#ccc] hover:outline-xumoTeal focus:outline-xumoBerry transition-colors relative p-2
            flex w-full max-w-full cursor-text resize-none justify-start transition-all placeholder:text-xs focus:placeholder:visible ${
              styleOverride.includes(' h-') ? '' : 'h-32'
            } ${styleOverride}`}
            name={name}
            value={value}
            onChange={handleChange}
            aria-errormessage={errorId}
          />
        </div>
      );
    default:
      return (
        <div className={`flex flex-col ${width === 'full' ? 'w-full' : 'w-full md:w-[48%]'}`}>
          <label htmlFor={name} className="mb-2">{placeholder}</label>
          <Field
            className={`w-full rounded-md outline outline-2 outline-[#ccc] hover:outline-xumoTeal focus:outline-xumoBerry transition-colors relative p-2 z-20
          cursor-text placeholder:text-xs hover:border-xumoSmoke focus:border-opacity-[42%] ${styleOverride}`}
            name={name}
            value={value}
            type={type}
            onChange={handleChange}
            aria-errormessage={errorId}
            data-i18n={t(placeholder)}
          />
        </div>
      );
  }
};
